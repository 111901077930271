import React from "react";
import { Box, Paper, Grid } from "@mui/material";
import * as LandingStyles from "../styles/Landing.module.css";
import bardbotimg from "../images/Bard+Avrae.png";
import profilesPageImg from "../../../discord-dungeon-user-docs/src/test/expected-images/overriding/profile-sections.jpg";
import soundsPageImg from "../images/soundsList.png";
import characterProfileImg from "../../../discord-dungeon-user-docs/src/images/characters/CreatedChar.png";
const features = [
  {
    title: "Avrae + Bard = ❤️",
    desc: "Bard Bot comes with dozens of built in sounds to enhance your D&D game right from the start! It will watch for Avrae messages and automatically play the corresponding sound effect. Or go further and upload your own sounds! You can even play sounds directly to your channel from the sounds page!",
    imgsrc: bardbotimg,
    alt: "BardBot",
  },
  {
    title: "The Sounds Page",
    desc: "To view and play the full set of default sounds visit the sounds page by logging with discord! You can play these sound effects directly to your voice chat through the sounds page, or through text chat commands in Discord!",
    imgsrc: soundsPageImg,
    alt: "SoundsPage",
  },
  {
    title: "Sound Profiles",
    desc: "Bard Bot lets you group your sounds into profiles, and easily change what profile is active. This lets you set up different sound effects for different characters, encounters, monsters, locations and more. It also combines sounds from the active profiles of everyone in the voice channel so all your friends can benefit from your custom sounds.",
    imgsrc: profilesPageImg,
    alt: "ProfilesPage",
  },
  {
    title: "Character Profiles",
    desc: "Bard Bot lets you separate sounds meant for everyone in your group from sounds meant for just you with character profiles. When it detects an Avrae message with a character name in it, Bard Bot will then look for an active character with that name and if it finds one check there for sounds first.",
    imgsrc: characterProfileImg,
    alt: "Characters",
  },
];

export const htmlContent = (
  <React.Fragment>
    <Box>
      <Box>
        <div id="discord-dungeon" className={LandingStyles.Title}>
          Discord Dungeon
        </div>
        <div className={LandingStyles.SecondaryTitle}>
          Home of Bard Bot - <a href="/docs/quick-start">Get Started</a>
        </div>

        <div
          id="-new-add-sounds-unique-to-your-character-docs-whats-new-"
          class={LandingStyles.SiteUpdate}
        >
          New:{" "}
          <a href="/docs/whats-new">
            Sound Folders, Bigger Sounds, Better errors.
          </a>
        </div>
      </Box>

      <Paper sx={{ flexGrow: 1, marginTop: "2rem" }} elevation={10}>
        <p id="features" class={LandingStyles.featuresTitle}>
          Bard Bot Features
        </p>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          sx={{ padding: "1rem" }}
        >
          {features.map((feature) => (
            <Grid item xs={12}>
              <Paper elevation={6} sx={{ padding: "1rem" }}>
                <div class={LandingStyles.PaperHeader}>{feature.title}</div>
                <p class={LandingStyles.PaperText}>{feature.desc}</p>
                <img
                  src={feature.imgsrc ? feature.imgsrc : ""}
                  class={LandingStyles.featurePicture}
                  alt={feature.alt}
                ></img>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Box>
        <Paper
          sx={{ flexGrow: 1, marginTop: "2rem", padding: "1rem" }}
          elevation={10}
        >
          <p id="getting-started" class={LandingStyles.featuresTitle}>
            Getting Started
          </p>
          <p class={LandingStyles.PaperText}>
            <ul>
              <li class={LandingStyles.ulStyle}>
                Check out the <a href="/docs/quick-start">Quick Start</a> to get
                up and running quickly.
              </li>
              <li class={LandingStyles.ulStyle}>
                Head to the <a href="/docs">Introduction</a> to read more about
                how Bard Bot can be used!
              </li>
            </ul>
            <p class={LandingStyles.PaperHeaderCentered}>Features in action!</p>
          </p>
          <Paper elevation={6} sx={{ padding: "1rem" }}>
            <p
              class={LandingStyles.PaperTextCentered}
              id="logging-in-the-default-sound-profile-and-making-and-using-custom-profiles"
            >
              Logging in, The Default Sound Profile, and Making and Using Custom
              Profiles
            </p>
            <div class={LandingStyles.youtubeVideo}>
              <iframe
                title="YouTube video player"
                width="auto"
                height="auto"
                src="https://www.youtube-nocookie.com/embed/jVlpCTDtv3w"
                frameborder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></iframe>
            </div>
          </Paper>
          <Paper elevation={6} sx={{ padding: "1rem", marginTop: "1rem" }}>
            <p
              class={LandingStyles.PaperTextCentered}
              id="uploading-custom-sounds-initiative-events-the-play-command-and-safe-mode"
            >
              Uploading Custom Sounds, Initiative Events, The ~play Command, and
              Safe Mode
            </p>
            <div class={LandingStyles.youtubeVideo}>
              <iframe
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/-QPZI-ga9iU"
                title="YouTube video player"
                frameborder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></iframe>
            </div>
          </Paper>
        </Paper>
      </Box>
    </Box>
  </React.Fragment>
);

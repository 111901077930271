import "antd/dist/antd.css";
import "../styles/index.css";
import "../styles/mui.min.css";
import "../styles/App.css";
import "../styles/style.css";

import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { htmlContent } from "../content/main";
import { CurrentUser } from "../server-state/CurrentUserContext";
import { Tracing } from "tracing-utils/tracing";
const padding = {
  paddingTop: "64px",
};
const IndexPage = () => (
  <Tracing>
    <CurrentUser>
      <Layout>
        <Seo title="Home" />
        <div className="embeddedContent" style={padding}>
          {htmlContent}
        </div>
      </Layout>
    </CurrentUser>
  </Tracing>
);

export default IndexPage;

// extracted by mini-css-extract-plugin
export var PaperHeader = "Landing-module--PaperHeader--2r11R";
export var PaperHeaderCentered = "Landing-module--PaperHeaderCentered--37qG5";
export var PaperText = "Landing-module--PaperText--mUr9j";
export var PaperTextCentered = "Landing-module--PaperTextCentered--1xeoy";
export var SecondaryTitle = "Landing-module--SecondaryTitle--37auT";
export var SiteUpdate = "Landing-module--SiteUpdate--2G75T";
export var Title = "Landing-module--Title--3cgdL";
export var featurePicture = "Landing-module--featurePicture--3r__P";
export var featuresTitle = "Landing-module--featuresTitle--wIIpD";
export var ulStyle = "Landing-module--ulStyle--K-Zrm";
export var youtubeVideo = "Landing-module--youtubeVideo--3t1HX";